/**
 * @description: 日志管理页面接口列表
 */

import request from './request'

// 获取日志列表
export function get_log_list (params) {
  return request({
    url: '/log_api/logs',
    method: 'get',
    params
  })
}

// 获取最新日志上传标志
export function get_upload_seq () {
  return request({
    url: '/log_api/log_upload_seq',
    method: 'get'
  })
}

/**
   * @description: 日志上传控制
   * @param {
   *  log_upload_seq: Number,
   *  lids: Array
   * }
   */
export function log_upload_seq (data) {
  return request({
    url: '/log_api/log_upload_seq',
    method: 'post',
    data
  })
}
// 日志分析
export function log_analysis (data) {
  return request({
    url: '/log_api/log_analyze',
    method: 'post',
    data
  })
}
// 日志打包下载
export function log_merger (data) {
  return request({
    url: '/log_api/log_merger',
    method: 'post',
    data
  })
}

export function download_log (params) {
  return request({
    url: '/log_api/log',
    method: 'get',
    responseType: 'blob',
    params
  })
}

// 日志保存天数
export function save_time(data) {
  return request({
    url: '/log_api/save_time',
    method: 'post',
    data
  })
}

// 获取硬盘详情
export function log_hd_space() {
  return request({
    url: '/log_api/log_hd_space',
    method: 'get'
  })
}