<template>
  <div class="wrapper">
    <h2>{{ $t("log.lognote") }}</h2>
    <h2>{{ $t("log.serverUpload") }}</h2>
    <el-form
      ref="serverForm"
      :model="serverForm"
      inline
      label-width="120px"
      :rules="serverFormRules"
    >
      <el-form-item
        :label="$t('log.server')"
        prop="server_id"
      >
        <el-select
          v-model="serverForm.server_id"
          :popper-append-to-body="false"
          filterable
        >
          <el-option
            v-for="item in serverOptions"
            :key="item.id"
            :value="item.id"
            :label="item.server_ip"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('time.timeRange')"
        prop="timeRange"
      >
        <el-date-picker
          v-model="serverForm.timeRange"
          :clearable="false"
          type="datetimerange"
          :picker-options="serverTimeOptions"
          :range-separator="$t('time.to')"
          :start-placeholder="$t('time.startTime')"
          :end-placeholder="$t('time.endTime')"
          align="right"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="confirmUploadLog"
        >
          {{ $t("common.confirm") }}
        </el-button>
      </el-form-item>
    </el-form>
    <h2>{{ $t('log.terminalUpload') }}</h2>
    <el-form
      ref="terminalForm"
      :model="terminalForm"
      inline
      label-width="120px"
      :rules="terminalFormRules"
    >
      <el-form-item
        :label="$t('log.terminal')"
        prop="terminal"
      >
        <el-select
          v-model="terminalForm.terminal"
          :popper-append-to-body="false"
          value-key="id"
          filterable
          @change="changeTerminal"
        >
          <el-option
            v-for="item in terminalList"
            :key="item.id"
            :value="item"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="LID"
        prop="terminal_lid"
      >
        <el-input
          v-model="terminalForm.terminal_lid"
          :disabled="isDisableLid"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="confirmUploadTerminalLog"
        >
          {{ $t("common.confirm") }}
        </el-button>
      </el-form-item>
    </el-form>
    <h2>{{ $t("log.saveLogDay") }}</h2>
    <el-form
      :model="saveDaysForm"
      ref="saveDaysFormRef"
      inline
      label-width="120px"
    >
    <div class="disk">
      <span class="label">{{ $t('log.diskSize') }}</span>
      <span class="value">{{ disk.size || '-' }}</span>
      <span class="label">{{ $t('log.diskAvailable') }}</span>
      <span class="value"> {{ disk.avail || '-' }}</span>
    </div>
      <el-form-item 
        :label="$t('log.saveDays')"
        prop="save_days"
        :rules="{required: true, message: $t('common.required')}"
      >
        <el-input v-model="saveDaysForm.save_days"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="confirmSaveDays"
        >
          {{ $t("common.confirm") }}
        </el-button>
      </el-form-item>
    </el-form>
    <h2>{{ $t('log.packageLog') }}</h2>
    <el-form
      label-width="120px"
      inline
    >
      <el-form-item :label="$t('time.timeRange')">
        <el-date-picker
          v-model="exportTimeRange"
          type="datetimerange"
          :picker-options="exportTimeOptions"
          :range-separator="$t('time.to')"
          :start-placeholder="$t('time.startTime')"
          :end-placeholder="$t('time.endTime')"
          align="right"
          @change="filterByDate"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="filterByDate"
        >
          {{ $t('log.filterLog') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="selectLog.length==0"
          @click="logMerge"
        >
          {{ $t("log.mergerDownload") }}
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="logList"
      border
      class="logTable"
      @select="handleSelect"
      @select-all="handleSelectAll"
    >
      <el-table-column type="selection" />
      <el-table-column
        :label="$t('log.logName')"
        prop="log_name"
      >
        <template slot-scope="scope">
          <el-link
            :underline="false"
            type="primary"
            :href="`${baseUrl}log_api/log?date=${scope.row.log_date}&log_name=${scope.row.log_name}&token=${token}`"
          >
            {{ scope.row.log_name }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('common.operate')"
        width="200"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            :disabled="scope.row.log_name.includes('ims')"
            type="primary"
            @click="handleAnalysis(scope.row)"
          >
            {{ $t("log.analysis") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="$t('log.damagedZip')"
      :visible.sync="damagedZipDialog"
    >
      <ol>
        <li
          v-for="(damagedZip, index) in damagedZipList"
          :key="damagedZip"
        >
          {{ `${index + 1}. ${damagedZip}` }}
        </li>
      </ol>
      <span slot="footer">
        <el-button
          type="primary"
          @click="damagedZipDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 分析结果 -->
    <el-dialog
      :title="$t('logDownload.analysisDetail')"
      :visible.sync="analysisDialog"
    >
      <div
        class="detailDesc"
        v-html="analysisDetails"
      />
      <span slot="footer">
        <el-button
          type="primary"
          @click="analysisDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 选择筛选日志内容的时间 -->
    <el-dialog
      :title="$t('logDownload.chooseTimeTitle')"
      :visible.sync="chooseFilterTimeDialog"
      width="800px"
    >
      <el-form inline>
        <el-form-item :label="$t('time.startTime')">
          <el-date-picker
            v-model="filterLogStartTime"
            type="datetime"
          />
        </el-form-item>
        <el-form-item :label="$t('time.endTime')">
          <el-date-picker
            v-model="filterLogEndTime"
            type="datetime"
          />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="chooseFilterTimeDialog = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="confirmAnalyze"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get_log_list, log_analysis, log_merger, download_log, save_time, log_hd_space } from '@/api/log.js'
import { get_server_list_select } from '@/api/config'
import { ims_log } from '@/api/server'
import { upload_terminal_log, get_terminals_by_type } from '@/api/terminal'
import { formatTime, blobDodnload, formatTimeToMDH } from '@/common/util.js'
import { customConfig } from '@/api/request.js'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      filterLogStartTime: '',
      filterLogEndTime: '',
      chooseFilterTimeDialog: false,
      analysisDialog: false,
      baseUrl: customConfig.baseURL,
      analysisDetails: '',
      saveDaysForm: {
        save_days: "7",
      },
      serverFormRules: {
        server_id: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        timeRange: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      serverOptions: [],
      serverForm: {
        timeRange: [new Date(new Date().getTime() - 3600 * 1000), new Date()],
        server_id: ''
      },
      serverTimeOptions: {
        shortcuts: [{
          text: this.$t('time.last1h'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('time.last4h'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 4)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('time.last24h'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      exportTimeOptions: {
        shortcuts: [{
          text: this.$t('time.last1h'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('time.last4h'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 4)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('time.last24h'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      terminalForm: {
        terminal: '',
        terminal_lid: ''
      },
      terminalList: [],
      terminalFormRules: {
        terminal: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        terminal_lid: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      isDisableLid: false,
      exportTimeRange: [new Date(new Date().getTime() - 3600 * 1000 * 24), new Date()],
      logList: [],
      selectLog: [],
      damagedZipDialog: false,
      damagedZipList: [],
      disk: {
        size: '',
        avail: ''
      }
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  created () {
    this.init()
  },
  methods: {
    // 分析
    async handleAnalysis (row) {
      this.logMsg = row
      this.chooseFilterTimeDialog = true
    },
    // 确认开始分析
    async confirmAnalyze () {
      const loading = this.$globalLoading(this.$t('common.analysising'))
      const params = {
        start_time: this.filterLogStartTime ? formatTimeToMDH(this.filterLogStartTime) : '',
        end_time: this.filterLogEndTime ? formatTimeToMDH(this.filterLogEndTime) : '',
        date: this.logMsg.log_date,
        log_name: this.logMsg.log_name
      }
      const res = await log_analysis(params)
      if (res.data.code === 0) {
        this.analysisDialog = true
        this.analysisDetails = res.data.data
        this.chooseFilterTimeDialog = false
      }
      loading.close()
    },
    // 获取服务器列表
    async getServerListSelect () {
      const res = await get_server_list_select()
      if (res.data.code === 0) {
        const result = res.data.data
        this.serverOptions = result.filter((item) => {
          // 过滤掉不能进行日志上传的类型
          return item.server_types !== 'iLive-Manager-Server'
        })
      }
    },
    confirmSaveDays() {
      this.$refs.saveDaysFormRef.validate(async(valid) => {
        console.log(valid)
        if(!valid) return
        const res = await save_time(this.saveDaysForm)
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.changeSuccess'))
        }
      })
    },
    handleSelect (selection) {
      this.selectLog = selection
    },
    handleSelectAll (selection) {
      this.selectLog = selection
    },
    // 通过时间对已上传日志进行筛选
    async filterByDate () {
      this.exportTimeRange = this.exportTimeRange ?? []
      const loading = this.$globalLoading()
      const params = {
        start_time: formatTime(this.exportTimeRange[0]),
        end_time: formatTime(this.exportTimeRange[1])
      }
      const res = await get_log_list(params)
      if (res.data.code === 0) {
        this.logList = res.data.data
      }
      loading.close()
    },
    // 打包导出已上传日志
    logMerge () {
      this.$confirm(
        '该操作需要对服务端日志进行大量操作，需要占用一定CPU资源，并可能耗时较长',
        this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel')
        }).then(async () => {
        const loading = this.$globalLoading()
        const params = {
          log_list: this.selectLog
        }
        const res = await log_merger(params)
        if (res.data.code === 0) {
          const result = res.data.data
          if (result.format_error_list.length !== 0) {
            this.damagedZipList = result.format_error_list
            this.damagedZipDialog = true
          }
          const zip = await download_log({
            date: result.merge_log_info.log_date,
            log_name: result.merge_log_info.log_name
          })
          blobDodnload(zip)
        }
        loading.close()
      }).catch(() => {})
    },
    // 获取所有的推流终端
    async getPushTeminals () {
      // 0 推流， 1 收流
      const res = await get_terminals_by_type({ terminal_type: 0 })
      if (res.data.code === 0) {
        this.terminalList = res.data.data
      }
    },
    // 如果终端自身存在lid，则禁止修改lid，若不存在lid，则必须填写lid
    changeTerminal (termianl) {
      if (termianl.lid) {
        this.terminalForm.terminal_lid = termianl.lid
        this.isDisableLid = true
      } else {
        this.isDisableLid = false
      }
    },
    // 确认上传终端日志
    confirmUploadTerminalLog () {
      this.$refs.terminalForm.validate(async (valid) => {
        if (!valid) return
        const res = await upload_terminal_log({
          terminal_id: this.terminalForm.terminal.id,
          terminal_lid: this.terminalForm.terminal_lid
        })
        if (res.data.code === 0) {
          this.$message.success(this.$t('editServer.submit'))
        }
      })
    },
    // 确认服务器日志上传
    confirmUploadLog () {
      this.$refs.serverForm.validate(async (valid) => {
        if (valid) {
          console.log(this.serverForm)
          const start = formatTime(this.serverForm.timeRange[0])
          const end = formatTime(this.serverForm.timeRange[1])
          const loading = this.$globalLoading()
          const res = await ims_log({
            start,
            end,
            server_id: this.serverForm.server_id
          })
          if (res.data.code === 0) {
            this.$message.success(this.$t('editServer.submit'))
          }
          loading.close()
        }
      })
    },
    async getDiskDetail() {
      const res = await log_hd_space()
      if (res.data.code === 0) {
        this.disk = res.data.data.space_info || { size: '', avail: '' }
      }
    },
    init () {
      this.getDiskDetail()
      this.getPushTeminals()
      this.getServerListSelect()
    }
  }
}
</script>

<style lang='scss' scoped>
.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* height: 100%;
  overflow-y: visible; */
  .form {
    width: 350px;
  }
  h2{
    line-height: 40px;
    margin-right: 50px;
    /* border-bottom: 1px dashed #909399; */
    margin-bottom: 20px;
  }
}
.remind {
  display: inline-block;
  line-height: 16px;
  font-size: 12px;
  color: #909399;
}
.bold {
  font-weight: bold;
}
.logTable {
  margin-left: 50px;
  width: 750px;
}
.disk {
  font-size: 16px;
  margin-bottom: 10px;
  .label {
    box-sizing: border-box;
    display: inline-block;
    width: 120px;
    color: #606266;
    text-align: right;
    padding-right: 10px;
  }
  .value {
    margin-right: 10px;
  }
}
.detailDesc {
   white-space: pre;
   line-height: 20px;
 }
</style>
